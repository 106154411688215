import { Type } from "class-transformer";
import Organization from "./Organization";

export default class AccessCategory {
  public id: number;
  public hash: string;
  public abbreviation = "";
  public name = "";
  public tag = "";
  public description = "";
  @Type(() => Organization)
  public organization: Organization | null = null;
  public projects_count = 0;
  public teams_count = 0;
  public archived_at: string | null = null;
}
