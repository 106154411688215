import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { paginatedResponseToClass, responseToClass } from "@/api/lib";
import Team from "@/models/Team";
import { AxiosPromise } from "axios";
import CreateViewModel from "@/forms/models/organization/team/CreateViewModel";
import PaginationResponse from "@/interfaces/responses/PaginationResponse";

export interface ITeamListOptions {
  page?: number;
  teamName?: string;
  sortBy?: string;
  order?: string;
  archived?: boolean;
}
export function list(
  organizationId: number,
  params: ITeamListOptions = {}
): AxiosPromise<PaginationResponse<Team[]>> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/teams`, {
      params:
        params.archived !== null
          ? { ...params, ...{ archived: params.archived === true ? 1 : 0 } }
          : { ...params },
    })
    .then((response) => paginatedResponseToClass(Team, response));
}

export function show(
  organizationId: string,
  teamId: string
): AxiosPromise<Team> {
  return $http
    .get(`${prefix}/organizations/${organizationId}/teams/${teamId}`)
    .then((response) => responseToClass(Team, response));
}

export function create(
  organizationId: number,
  form: CreateViewModel
): AxiosPromise<Team> {
  return $http
    .post(`${prefix}/organizations/${organizationId}/teams`, form)
    .then((response) => responseToClass(Team, response));
}
