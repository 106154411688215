import Organization from "@/models/Organization";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "@/store";
import { ApiClient } from "@/api";
import { AxiosPromise } from "axios";

export enum MutationTypes {
  SetOrganization = "SetOrganization",
}

export interface IOrganizationState {
  organization: Organization | null;
}

const state: IOrganizationState = {
  organization: null,
};

const getters: GetterTree<IOrganizationState, RootState> = {};
const actions: ActionTree<IOrganizationState, RootState> = {
  load(
    { commit, state, rootState }: ActionContext<IOrganizationState, RootState>,
    organizationId: number
  ): AxiosPromise<Organization> {
    const user = rootState.user.user;

    if (!user) {
      return Promise.reject("user undefined");
    }

    return ApiClient.User.Organization.show(user.hash, organizationId).then(
      (response) => {
        commit(MutationTypes.SetOrganization, response.data);
        return response;
      }
    );
  },
  setOrganization(
    { commit }: ActionContext<IOrganizationState, RootState>,
    organization: Organization | null
  ) {
    commit(MutationTypes.SetOrganization, organization);
  },
};
const mutations: MutationTree<IOrganizationState> = {
  [MutationTypes.SetOrganization](
    state: IOrganizationState,
    organization: Organization | null
  ) {
    state.organization = organization;
  },
};

export const organization: Module<IOrganizationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
