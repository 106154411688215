






































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { Route } from "vue-router";
import { namespace } from "vuex-class";
import User from "@/models/User";
import { DownloadProgress, MutationTypes } from "@/store/modules/downloads";
import Team, { AmplitudeTeamType } from "@/models/Team";
import DefaultUser from "@/components/defaults/DefaultUser.vue";
import UserIcon from "!!raw-loader!@/../node_modules/@fortawesome/fontawesome-pro/svgs/solid/user.svg";
import { IUserSupportConfig } from "@/store/modules/user";
import { authenticationPromise } from "@/services/Auth";
import { FrontendConfiguration } from "@/apiClientGenerated";
import { configuration } from "@/config/dynamic";
import { getEnvFromHost } from "@/config/app";

const user = namespace("user");
const downloads = namespace("downloads");

@Component({
  computed: {
    AmplitudeTeamType() {
      return AmplitudeTeamType;
    },
  },
  components: {
    DefaultUser,
  },
})
export default class AppNavbar extends Vue {
  @user.State("user") user!: User;
  @user.Getter("hasAdminDashboardAccess") hasAdminDashboardAccess!: boolean;
  @user.Getter("teamsWithProtocolsModule") teamsWithProtocolsModule!: Team[];
  @user.Getter("supportConfig") supportConfig!: IUserSupportConfig;
  @downloads.Getter("activeDownloads") activeDownloads!: DownloadProgress[];
  @downloads.Getter("recentDownloads") recentDownloads!: DownloadProgress[];
  @downloads.Getter("completedDownloads")
  completedDownloads!: DownloadProgress[];
  @downloads.Mutation(MutationTypes.ClearDownloadHistory)
  clearDownloadHistory!: () => void;
  toggleMenuMobile = false;
  query = "";
  configuration: FrontendConfiguration | null = null;
  mounted(): void {
    configuration.getInstance().then((config) => (this.configuration = config));
  }

  isActive(prefix: string, route: Route): boolean {
    return route.path.startsWith(prefix);
  }
  redirectTo(to: Route): void {
    this.toggleMenuMobile = false;
    this.$router.push(to);
  }
  handleSearch(): void {
    this.$router.push({
      name: "search",
      query: {
        query: this.query,
      },
    });
  }
  logout(): void {
    authenticationPromise
      .getInstance()
      .then((authentication) => authentication.logout());
  }
  get home(): string {
    return this.configuration?.homeRoute || "news";
  }
  get icon(): string {
    return UserIcon;
  }
  get supportCenterUrl(): string {
    return this.supportConfig.supportUrl;
  }

  get showDemoAcademic(): boolean {
    return (
      ["dev", "demo"].includes(getEnvFromHost()) &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Academic
    );
  }

  get showDemoIndustry(): boolean {
    return (
      ["dev", "demo"].includes(getEnvFromHost()) &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Industry
    );
  }
  get showDemoExperts(): boolean {
    return (
      ["dev", "demo"].includes(getEnvFromHost()) &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Industry
    );
  }

  get showDemoContacts(): boolean {
    return (
      ["dev", "demo"].includes(getEnvFromHost()) &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Academic
    );
  }

  get showDemoTalents(): boolean {
    return (
      ["demo"].includes(getEnvFromHost()) &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Industry
    );
  }

  get showTalents(): boolean {
    return (
      !["demo"].includes(getEnvFromHost()) &&
      !!this.user?.organization?.src_features &&
      this.user?.main_team?.amplitude_team_type === AmplitudeTeamType.Industry
    );
  }

  handleShowTalents(): void {
    this.toggleMenuMobile = false;
    window.location.href = "/v2/talents";
  }
}
