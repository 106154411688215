import cubejs, {
  CubejsApi,
  Filter,
  Meta,
  Query,
  ResultSet,
} from "@cubejs-client/core";
import Store from "@/store";
import { RawLocation } from "vue-router";
import * as _ from "lodash";
import moment from "moment";
import User from "@/models/User";
import { LocalStorageHelper } from "@/utils/LocalStorageHelper";
import { ApiClient } from "@/api";
import { TokenQueryParams } from "@/api/namespaces/user/cubeJs";
import { resolveCubejsUrl } from "@/config/app";
import { configuration } from "@/config/dynamic";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/vue";

export enum FilterVariables {
  TeamId = "%TEAM_ID%",
}

export enum ReportScope {
  Organization = "organization",
  Team = "team",
}

export enum ReportColumnType {
  Text = "text",
  Numeric = "numeric",
  Date = "date",
  Link = "link",
  File = "file",
  Boolean = "boolean",
}

interface BaseReportColumn {
  title: string;
  key: string;
  freeze?: "Left" | "Right";
  width?: number;
  minWidth?: number;
}

export interface TextReportColumn extends BaseReportColumn {
  type: ReportColumnType.Text;
}

export interface DateReportColumn extends BaseReportColumn {
  type: ReportColumnType.Date;
}

export interface NumericReportColumn extends BaseReportColumn {
  type: ReportColumnType.Numeric;
}

export interface BooleanReportColumn extends BaseReportColumn {
  type: ReportColumnType.Boolean;
  labels: { true: string; false: string };
}

export interface LinkReportColumn extends BaseReportColumn {
  type: ReportColumnType.Link;
  linkAccessor: (row: Record<string, string | number>) => RawLocation;
}

export interface FileReportColumn extends BaseReportColumn {
  type: ReportColumnType.File;
  fileIdMember: string;
}

export type TemplatedReportColumn = LinkReportColumn | FileReportColumn;

export type ReportColumn =
  | TextReportColumn
  | NumericReportColumn
  | DateReportColumn
  | TemplatedReportColumn
  | BooleanReportColumn;

export enum TimeDimensionType {
  DayRange,
  Moment,
}

export enum TimeDimensionPosition {
  Top = "top",
  Bottom = "bottom",
}

export type FilterableDimensionConfig = {
  dimension: string;
  label?: string;
  defaultValue?: string;
  multiple?: boolean;
};

export type ColumnSortDirection = "Ascending" | "Descending";

export interface SortColumnConfig {
  field: string;
  direction: ColumnSortDirection;
}

interface BaseReportConfiguration {
  name: string;
  section: string;
  scope: ReportScope;
  query: Query;
  filterableDimensions: FilterableDimensionConfig[];
  columns: ReportColumn[];
  timeDimensionPosition?: TimeDimensionPosition;
  sortColumns?: SortColumnConfig[];
  zipExport?: boolean;
}

interface UndatedReportConfiguration extends BaseReportConfiguration {
  hasDateFilters: false;
}

interface DatedReportConfiguration extends BaseReportConfiguration {
  hasDateFilters: true;
}

interface DayRangeDatedReportConfiguration extends DatedReportConfiguration {
  timeDimensionType: TimeDimensionType.DayRange;
  timeDimension: {
    startsAt: string;
    endsAt: string;
  };
}

interface MomentDatedReportConfiguration extends DatedReportConfiguration {
  timeDimensionType: TimeDimensionType.Moment;
  timeDimension: string;
}

type CubeTokenStorage = {
  token: string;
};

type CubeMultiTokenStorage = {
  [index in ReportScope]: {
    [index: string]: CubeTokenStorage;
  };
};

export type ReportConfiguration =
  | UndatedReportConfiguration
  | DayRangeDatedReportConfiguration
  | MomentDatedReportConfiguration;

export interface ReportFilterDropdownConfig {
  values: string[];
  label: string;
  name: string;
  defaultValue?: string | number;
  selectedValue: [string] | [] | string[];
  multiple: boolean;
}

const getDefaultDateRange = () => ({
  startsAt: null,
  endsAt: null,
});

export const CUBE_JS_TOKEN_STORAGE_KEY = "cubejs_tokens";
export const CUBE_JS_QUERY_LIMIT = 10000;
export const CUBE_JS_DROPDOWN_QUERY_LIMIT = 50000;

const DEFAULT_QUERY: Query = {
  limit: CUBE_JS_QUERY_LIMIT,
};

const DEFAULT_DROPDOWN_QUERY: Query = {
  limit: CUBE_JS_DROPDOWN_QUERY_LIMIT,
};

const errorHandler = (error: any) => {
  const title =
    error &&
    error.response &&
    error.response.error &&
    error.response.error === "Error: Invalid token"
      ? "Your authentication is invalid or has expired"
      : "An error occurred";

  Sentry.captureException(error);

  return Swal.fire({
    icon: "error",
    title,
    text: `Try reloading the page using the Reload button to fix the problem. If it persists, contact support`,
    confirmButtonText: "Reload",
    showCancelButton: true,
    cancelButtonText: "Stay",
  }).then((input) => {
    if (input.value) {
      LocalStorageHelper.remove(CUBE_JS_TOKEN_STORAGE_KEY);
      window.location.reload();
    }
    return [];
  });
};

// @todo: Make ReportModel great again
// I don't have enough time to make it enough very clean.
// We should wrap the cubeJsApi into a service class.
// This class would manage all the token stuff (refresh, store it locally, generating the new client class, etc)
// It would better fit the single responsability principle, and it would simplify the code of this class.

export class ReportModel {
  private _meta?: Meta;
  private _filtersDropdownConfig: ReportFilterDropdownConfig[] = [];
  private _isInitialLoading = true;
  private _isDataLoading = false;
  private _selectedDateRanges: {
    startsAt: Date | null;
    endsAt: Date | null;
  } = getDefaultDateRange();
  private _timeDimensionPosition = TimeDimensionPosition.Top;
  private _data: Record<string, string>[] = [];
  private _hasGenerateBeenCalled = false;
  private _dirty = false;
  private _filterAutocomplete: Record<string, string> = {};

  constructor(private _config: ReportConfiguration, private _user: User) {
    if (this._config.timeDimensionPosition) {
      this.timeDimensionPosition = this._config.timeDimensionPosition;
    }
  }

  private parseJwt(token: string): { exp: number; sub: string; iat: number } {
    return JSON.parse(atob(token.split(".")[1]));
  }

  private async fetchToken(): Promise<string> {
    const scope = this._config.scope;
    const resourceId = scopeIdentifierValue(scope);
    const queryParams = ScopeTopQueryParamsAccessorMap[scope]();
    const tokenResponse = await ApiClient.User.CubeJs.token(
      this._user.hash,
      queryParams
    );
    const token = tokenResponse.data.token;

    const newState: CubeMultiTokenStorage = (LocalStorageHelper.get(
      CUBE_JS_TOKEN_STORAGE_KEY
    ) as CubeMultiTokenStorage) || {
      [ReportScope.Team]: {},
      [ReportScope.Organization]: {},
    };

    Object.assign(newState[scope], {
      [resourceId]: {
        token,
      },
    });

    LocalStorageHelper.put(CUBE_JS_TOKEN_STORAGE_KEY, newState);
    return token;
  }

  private async getToken(): Promise<string> {
    const scope = this._config.scope;
    const resourceId = scopeIdentifierValue(scope);

    const storedTokens = (LocalStorageHelper.get(
      CUBE_JS_TOKEN_STORAGE_KEY
    ) as CubeMultiTokenStorage) || {
      [ReportScope.Team]: {},
      [ReportScope.Organization]: {},
    };

    const storedToken =
      storedTokens && storedTokens[scope] && storedTokens[scope][resourceId]
        ? storedTokens[scope][resourceId]
        : undefined;

    if (storedToken) {
      const encodedToken = storedToken.token;
      const decodedToken = this.parseJwt(encodedToken);

      const BUFFER_IN_SECONDS = 10;

      const { resetTokenIssuedBefore } = await configuration.getInstance();
      const shouldReset =
        resetTokenIssuedBefore && decodedToken.iat < resetTokenIssuedBefore;

      const isTokenExpired =
        decodedToken.exp < Math.round(Date.now() / 1000) - BUFFER_IN_SECONDS;

      const isCurrentUser = decodedToken.sub === this._user.email;

      return shouldReset || isTokenExpired || !isCurrentUser
        ? this.fetchToken()
        : encodedToken;
    }

    return this.fetchToken();
  }

  private getCubejsApi(): Promise<CubejsApi> {
    return this.getToken().then((token) =>
      cubejs(token, {
        apiUrl: resolveCubejsUrl(window.location.host),
      })
    );
  }

  async fetchDimensionFiltersValues(): Promise<void> {
    const timeStart = Date.now();

    Sentry.metrics.increment("report_filter_load_started", 1, {
      tags: { reportTitle: this.title },
      scope: this._config.scope,
    });
    this._isInitialLoading = true;
    const filterableDimensions = this._config.filterableDimensions;
    const api = await this.getCubejsApi();
    try {
      const resultSets: ResultSet[] = await Promise.all(
        filterableDimensions.map((filterableDimension) =>
          api.load({
            ...DEFAULT_DROPDOWN_QUERY,
            dimensions: [filterableDimension.dimension],
          })
        )
      ).catch(errorHandler);

      Sentry.metrics.distribution(
        "report_filter_load_execution_time",
        Date.now() - timeStart,
        {
          tags: { reportTitle: this.title, scope: this._config.scope },
          unit: "millisecond",
        }
      );
      this._filtersDropdownConfig = resultSets.map((resultSet, i) => {
        const { label, dimension, defaultValue, multiple } =
          filterableDimensions[i];
        return {
          label: String(label),
          name: dimension,
          selectedValue:
            typeof defaultValue !== "undefined" ? [defaultValue] : [],
          values: resultSet
            .rawData()
            .map((value) => value[dimension.toString()]),
          multiple: !!multiple,
          defaultValue,
        };
      });
    } catch (e) {
      Sentry.metrics.increment("report_filter_load_failed", 1, {
        tags: { reportTitle: this.title, scope: this._config.scope },
      });

      Sentry.captureException(e);
    }

    this._isInitialLoading = false;
  }

  async clear(): Promise<void> {
    this._selectedDateRanges = getDefaultDateRange();
    this._filterAutocomplete = {};
    this._filtersDropdownConfig = _.map(
      this._filtersDropdownConfig,
      (filter) => {
        return {
          ...filter,
          selectedValue: filter.defaultValue
            ? [filter.defaultValue.toString()]
            : [],
        };
      }
    );
    this.setDirtyIfGenerated();
  }

  get query(): Query {
    const query = { ...DEFAULT_QUERY, ...this._config.query }; // todo deep clone would be better
    query.filters = [
      ...this.buildFiltersFromQuery(),
      ...this.buildFiltersFromCurrentSelectedValues(),
      ...this.buildTimeFilters(),
    ];

    return query;
  }

  buildFiltersFromQuery(): Filter[] {
    if (this._config.query.filters) {
      const teamId = Store.state.team.team?.id.toString() || "";

      return JSON.parse(
        JSON.stringify(this._config.query.filters).replaceAll(
          FilterVariables.TeamId,
          teamId
        )
      ) as Filter[];
    }

    return [];
  }

  buildTimeFilters(): Filter[] {
    const timeFilters: Filter[] = [];
    if (this._config.hasDateFilters) {
      switch (this._config.timeDimensionType) {
        case TimeDimensionType.DayRange:
          if (this.startsAt) {
            timeFilters.push({
              member: this._config.timeDimension.startsAt,
              operator: "afterDate",
              values: [
                moment(this.startsAt).subtract(1, "days").toDate().toString(),
              ],
            });
          }
          if (this.endsAt) {
            timeFilters.push({
              member: this._config.timeDimension.endsAt,
              operator: "beforeDate",
              values: [moment(this.endsAt).add(1, "days").toDate().toString()],
            });
          }
          break;
        case TimeDimensionType.Moment:
          if (this.startsAt) {
            timeFilters.push({
              member: this._config.timeDimension,
              operator: "afterDate",
              values: [
                moment(this.startsAt).subtract(1, "days").toDate().toString(),
              ],
            });
          }
          if (this.endsAt) {
            timeFilters.push({
              member: this._config.timeDimension,
              operator: "beforeDate",
              values: [moment(this.endsAt).add(1, "days").toDate().toString()],
            });
          }
          break;
      }
    }

    return timeFilters;
  }

  get filtersDropdownConfig(): ReportFilterDropdownConfig[] {
    return this._filtersDropdownConfig;
  }

  get isInitialLoading(): boolean {
    return this._isInitialLoading;
  }

  get isDataLoading(): boolean {
    return this._isDataLoading;
  }

  get title(): string {
    return this._config.name;
  }

  async meta(): Promise<Meta> {
    const api = await this.getCubejsApi();
    return this._meta ? this._meta : await api.meta();
  }

  private buildFiltersFromCurrentSelectedValues(): Filter[] {
    return this._filtersDropdownConfig
      .filter(
        (
          fd
        ): fd is Omit<ReportFilterDropdownConfig, "selectedValue"> & {
          selectedValue: [string];
        } => fd.selectedValue.length > 0
      )
      .map((fd) => {
        if (fd.multiple) {
          return {
            member: fd.name,
            operator: "equals",
            values: fd.selectedValue,
          };
        } else {
          const filterValue = fd.selectedValue[0];
          return {
            member: fd.name,
            operator: "equals",
            values: [filterValue],
          };
        }
      });
  }

  async generate(): Promise<void> {
    this._isDataLoading = true;
    this._dirty = false;
    const api = await this.getCubejsApi();
    try {
      this._data = (await api.load(this.query)).rawData();
      this._hasGenerateBeenCalled = true;
    } catch (error) {
      errorHandler(error);
    } finally {
      this._isDataLoading = false;
    }
  }

  setFilterAutocompleteValue(filterName: string, value: string): void {
    this._filterAutocomplete[filterName] = value;
    this.setDirtyIfGenerated();
  }

  private setDirtyIfGenerated() {
    if (this._hasGenerateBeenCalled) {
      this._dirty = true;
    }
  }

  getFilterAutocompleteValue(filterName: string): string {
    return this._filterAutocomplete[filterName];
  }

  get columns(): ReportColumn[] {
    return this._config.columns;
  }

  get hasDateFilters(): boolean {
    return this._config.hasDateFilters;
  }

  get startsAt(): Date | null {
    return this._selectedDateRanges.startsAt;
  }

  set startsAt(date: Date | null) {
    this._selectedDateRanges.startsAt = date;
    this.setDirtyIfGenerated();
  }

  get endsAt(): Date | null {
    return this._selectedDateRanges.endsAt;
  }

  set endsAt(date: Date | null) {
    this._selectedDateRanges.endsAt = date;
    this.setDirtyIfGenerated();
  }

  get timeDimensionPosition(): TimeDimensionPosition {
    return this._timeDimensionPosition;
  }

  set timeDimensionPosition(position: TimeDimensionPosition) {
    this._timeDimensionPosition = position;
    this.setDirtyIfGenerated();
  }

  get data(): Record<string, string>[] {
    return this._data;
  }

  get hasGenerateBeenCalled(): boolean {
    return this._hasGenerateBeenCalled;
  }

  get filtersCount(): number {
    return (
      this._config.filterableDimensions.length +
      (this._config.hasDateFilters ? 2 : 0)
    );
  }

  get dirty(): boolean {
    return this._dirty;
  }

  get zipExport(): boolean {
    return !!this._config.zipExport;
  }
}

function scopeIdentifierValue(scope: ReportScope) {
  switch (scope) {
    case ReportScope.Team:
      return Store.state.team.team?.id.toString() || "";
    case ReportScope.Organization:
      return Store.state.organization.organization?.id.toString() || "";
  }
}

const ScopeTopQueryParamsAccessorMap: Record<
  ReportScope,
  () => TokenQueryParams
> = {
  [ReportScope.Organization]: () => ({
    organizationId: scopeIdentifierValue(ReportScope.Organization),
  }),
  [ReportScope.Team]: () => ({
    teamId: scopeIdentifierValue(ReportScope.Team),
  }),
};
