import { AxiosPromise } from "axios";
import Dataset from "@/models/Dataset";
import $http from "@/services/Http";
import { prefix } from "@/api/config";
import { responseToClass } from "@/api/lib";
import CreateFormRequest from "@/forms/requests/project/publication/CreateFormRequest";

export function root(projectId: string): AxiosPromise<Dataset> {
  return $http
    .get(`${prefix}/projects/${projectId}/dataset`)
    .then((response) => responseToClass(Dataset, response));
}

export function publish(
  projectId: string,
  data: CreateFormRequest
): AxiosPromise<Dataset> {
  return $http
    .post(`${prefix}/projects/${projectId}/publish-files`, data)
    .then((response) => response);
}
