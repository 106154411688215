var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar is-primary",
      attrs: { role: "navigation", "aria-label": "main navigation" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "navbar-brand" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: { name: _vm.home } } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/logo-full-white.png"),
                    alt: "Pillar Science Logo",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "navbar-burger",
                class: { "is-active": _vm.toggleMenuMobile },
                on: {
                  click: function ($event) {
                    _vm.toggleMenuMobile = !_vm.toggleMenuMobile
                  },
                },
              },
              [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "navbar-menu is-hidden-print",
            class: { "is-active": _vm.toggleMenuMobile },
          },
          [
            _c(
              "div",
              { staticClass: "navbar-end" },
              [
                _c(
                  "div",
                  { staticClass: "navbar-item" },
                  [
                    _c(
                      "b-field",
                      [
                        _c("b-input", {
                          attrs: { placeholder: "Search everywhere" },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.query,
                            callback: function ($$v) {
                              _vm.query = $$v
                            },
                            expression: "query",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "control" },
                          [
                            _c("b-button", {
                              attrs: { "icon-left": "search" },
                              on: { click: _vm.handleSearch },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "navbar-item",
                    attrs: { target: "_blank", href: _vm.supportCenterUrl },
                    on: {
                      click: function ($event) {
                        _vm.toggleMenuMobile = false
                      },
                    },
                  },
                  [
                    _c("fa-icon", { attrs: { icon: "question-circle" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Help")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showTalents
                  ? _c(
                      "a",
                      {
                        staticClass: "navbar-item",
                        class: {
                          "is-active": _vm.isActive("/v2/talents", _vm.$route),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleShowTalents()
                          },
                        },
                      },
                      [
                        _c("fa-icon", {
                          attrs: { icon: ["fas", "user-graduate"] },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Talents")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDemoTalents
                  ? _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        class: {
                          "is-active": _vm.isActive("/ai/talents", _vm.$route),
                        },
                        attrs: { to: { name: "demo_talents" } },
                        nativeOn: {
                          click: function ($event) {
                            _vm.toggleMenuMobile = false
                          },
                        },
                      },
                      [
                        _c("fa-icon", {
                          attrs: { icon: ["fas", "user-graduate"] },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Talents")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDemoExperts
                  ? _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        class: {
                          "is-active": _vm.isActive("/ai/experts", _vm.$route),
                        },
                        attrs: { to: { name: "demo_experts" } },
                        nativeOn: {
                          click: function ($event) {
                            _vm.toggleMenuMobile = false
                          },
                        },
                      },
                      [
                        _c("fa-icon", { attrs: { icon: "user-tie" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Experts")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDemoContacts
                  ? _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        class: {
                          "is-active": _vm.isActive("/ai/contacts", _vm.$route),
                        },
                        attrs: { to: { name: "demo_contacts" } },
                        nativeOn: {
                          click: function ($event) {
                            _vm.toggleMenuMobile = false
                          },
                        },
                      },
                      [
                        _c("fa-icon", { attrs: { icon: "address-book" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Contacts")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "navbar-item",
                    class: {
                      "is-active": _vm.isActive("/projects", _vm.$route),
                    },
                    attrs: { to: { name: "projects" } },
                    nativeOn: {
                      click: function ($event) {
                        _vm.toggleMenuMobile = false
                      },
                    },
                  },
                  [
                    _c("fa-icon", { attrs: { icon: "project-diagram" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Projects")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.teamsWithProtocolsModule.length > 0
                  ? _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        class: {
                          "is-active": _vm.isActive("/protocols", _vm.$route),
                        },
                        attrs: {
                          to: { name: "protocols" },
                          "test-id": "navbar-tab-protocol",
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.toggleMenuMobile = false
                          },
                        },
                      },
                      [
                        _c("fa-icon", { attrs: { icon: "flask" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Methods")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "navbar-item",
                    class: { "is-active": _vm.isActive("/tasks", _vm.$route) },
                    attrs: { to: { name: "tasks" } },
                    nativeOn: {
                      click: function ($event) {
                        _vm.toggleMenuMobile = false
                      },
                    },
                  },
                  [
                    _c("fa-icon", { attrs: { icon: "tasks" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Tasks")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.recentDownloads.length > 0
                  ? _c(
                      "b-dropdown",
                      { attrs: { position: "is-bottom-left" } },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          },
                          [
                            _c(
                              "fa-layers",
                              [
                                _vm.activeDownloads.length > 0
                                  ? _c("fa-icon", {
                                      attrs: {
                                        icon: ["fal", "sync"],
                                        transform: "grow-10",
                                        spin: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("fa-icon", {
                                  attrs: { icon: "arrow-to-bottom" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.activeDownloads.length > 0
                              ? [
                                  _vm._v("\n                 \n              "),
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass: "tag-condensed",
                                      attrs: { type: "is-danger", rounded: "" },
                                    },
                                    [_vm._v(_vm._s(_vm.activeDownloads.length))]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.recentDownloads, function (download) {
                          return _c(
                            "b-dropdown-item",
                            { key: download.id, attrs: { custom: "" } },
                            [
                              _c("b", [_vm._v(_vm._s(download.name))]),
                              _vm._v(" "),
                              _c("b-progress", {
                                attrs: {
                                  type:
                                    download.progress === 100
                                      ? "is-success"
                                      : "is-info",
                                  value: download.progress,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.completedDownloads.length > 0
                          ? _c(
                              "b-dropdown-item",
                              { on: { click: _vm.clearDownloadHistory } },
                              [
                                _vm._v(
                                  "\n            Clear history\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.user
                  ? _c(
                      "b-dropdown",
                      { attrs: { position: "is-bottom-left" } },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: { slot: "trigger", "test-profile-menu": "" },
                            slot: "trigger",
                          },
                          [
                            _vm.user.profile_photo
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "navbar-photo navbar-photo-small is-hidden-touch",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm._f("publicDownloadUrl")(
                                          _vm.user.profile_photo.hash
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c("default-user", {
                                  staticClass:
                                    "svg-inline--fa fa-w-14 is-filled-grey",
                                }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.user.name))]),
                            _vm._v(" "),
                            _c("b-icon", { attrs: { icon: "caret-down" } }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "has-link": "" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "user_profile",
                                    params: { user_id: _vm.user.hash },
                                  },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "user" } }),
                                _vm._v(" "),
                                _c("span", [_vm._v("My profile")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "has-link": "" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { name: "settings_notifications" },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "user-cog" } }),
                                _vm._v(" "),
                                _c("span", [_vm._v("My Settings")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("hr", { staticClass: "dropdown-divider" }),
                        _vm._v(" "),
                        _vm.user && _vm.user.teams.length
                          ? [
                              _vm._l(_vm.user.teams, function (team) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: "team-" + team.id,
                                    attrs: { "has-link": "" },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "team_overview",
                                            params: { id: team.hash },
                                          },
                                        },
                                      },
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "users" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(team.name))]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c("hr", { staticClass: "dropdown-divider" }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user &&
                        (_vm.user.isOrgAdmin || _vm.user.hasManagementGroup)
                          ? [
                              _c(
                                "b-dropdown-item",
                                {
                                  key: "org-" + _vm.user.organization.id,
                                  attrs: { "has-link": "" },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "organization_manage_users",
                                          params: {
                                            organization_id:
                                              _vm.user.organization.hash,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: ["fas", "cog"] },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Admin Panel")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("hr", { staticClass: "dropdown-divider" }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasAdminDashboardAccess
                          ? [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo({
                                        name: "admin_home",
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("fa-icon", {
                                    attrs: { icon: ["fas", "bolt"] },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("Platform Administration"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("hr", { staticClass: "dropdown-divider" }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              value: "logout",
                              "test-logout": "",
                              "sentry-breadcrumb": "Navbar Logout",
                            },
                            on: { click: _vm.logout },
                          },
                          [
                            _c("fa-icon", { attrs: { icon: "sign-out" } }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Logout")]),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }