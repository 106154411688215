import User from "@/models/User";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "@/store";
import { ApiClient } from "@/api";
import { exclude } from "@/utils/Object";
import { AxiosPromise } from "axios";
import { TeamPermissions } from "@/staticCollections/teamPermissions";
import UserPermissions from "@/models/UserPermissions";
import UpdateFormRequest from "@/api/requests/user/UpdateFormRequest";
import GenericMutations, {
  MutationTypes as GenericMutationTypes,
} from "@/store/generic-mutations";

export interface IUserState {
  user: User | null;
}

export interface IUserSupportConfig {
  supportApiKey: string;
  supportUrl: string;
}

const state: IUserState = {
  user: null,
};

export enum MutationTypes {
  SetUser = "SetUser",
  ClearUser = "ClearUser",
  UpdateUser = "UpdateUser",
}

const getters: GetterTree<IUserState, RootState> = {
  isAuthenticated: (state) => state.user && state.user.id !== null,
  teams: (state) => {
    if (!state.user) {
      return [];
    }

    return state.user.teams;
  },
  teamsWithOnlyOfficeModule: (state) => {
    if (!state.user) {
      return [];
    }

    return state.user.teams.filter((t) =>
      t.permissions.includes(TeamPermissions.OnlyOfficeModule)
    );
  },
  teamsWithProtocolsModule: (state) => {
    if (!state.user) {
      return [];
    }

    return state.user.teams.filter((t) =>
      t.permissions.includes(TeamPermissions.ProtocolsModule)
    );
  },
  teamsAllowedToCreateProjects: (state) => {
    if (!state.user) {
      return [];
    }

    const teams =
      state.user.main_team && !state.user.main_team.allow_external_sharing
        ? [state.user.main_team]
        : state.user.teams;

    return teams.filter((t) => t.allow_create_project);
  },
  hasAdminDashboardAccess: (state) => {
    if (!state.user || !state.user.permissions) {
      return false;
    }

    return state.user.permissions.includes(UserPermissions.userTeamManagement);
  },
  hasUserTeamManagementPermission: (state) =>
    state.user &&
    state.user.permissions.includes(UserPermissions.userTeamManagement),
  hasDebugToolsPermission: (state) =>
    state.user && state.user.permissions.includes(UserPermissions.debugTools),
  needsToAcceptUserAgreement: (state): boolean => {
    if (!state.user) {
      return false;
    }

    return !state.user.has_accepted_license_agreement;
  },
  hasFillOnboarding: (state): boolean => {
    if (!state.user) {
      return false;
    }

    return state.user.onboarding_completed;
  },
  canEditProfile: (state): boolean => {
    return state.user.organization.allow_users_edit_profile;
  },
  supportConfig: (state) => {
    const defaultSupportConfig = {
      supportApiKey: "e01988fc-8ebc-47f2-b6ed-276199d27991",
      supportUrl: "https://pillarscience.zendesk.com/hc/en-us",
    };

    if (state.user) {
      const organizationWithSupportConfig = state.user.organization;

      return {
        supportApiKey:
          organizationWithSupportConfig?.support_api_key ||
          defaultSupportConfig.supportApiKey,
        supportUrl:
          organizationWithSupportConfig?.support_url ||
          defaultSupportConfig.supportUrl,
      };
    }

    return defaultSupportConfig;
  },
  hasSrcFeatures: (state): boolean => {
    return !!state.user?.organization.src_features;
  },
};

const actions: ActionTree<IUserState, RootState> = {
  updateUser(
    { commit, state }: ActionContext<IUserState, RootState>,
    form: UpdateFormRequest
  ): AxiosPromise<User> {
    if (!state.user) {
      return Promise.reject("User is not defined");
    }

    return ApiClient.User.update(
      state.user.hash,
      exclude(
        form,
        ["id", "name", "email", "password"],
        true,
        new UpdateFormRequest()
      )
    )
      .then((response) => {
        return ApiClient.Auth.me();
      })
      .then((response) => {
        commit(GenericMutationTypes.SetField, {
          field: "user",
          value: response.data,
        });

        return response;
      });
  },
};

const mutations: MutationTree<IUserState> = {
  ...GenericMutations,
  [MutationTypes.SetUser](state, user) {
    state.user = user;
    window.dispatchEvent(new Event("userSet"));
  },
  [MutationTypes.ClearUser](state) {
    state.user = null;
  },
  [MutationTypes.UpdateUser](state, form) {
    Object.assign(state.user, form);
  },
};

export const user: Module<IUserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
